/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import NavMenu from "../../../stories/QPFPDashboard/NavMenu";
import HeroSection from "../../../stories/QPFPDashboard/HeroSection";
import MainSection from "./MainSection";
import _ from "lodash";
import { useProfile } from "../../../context/userProvider";
import { useNavigate } from "@reach/router";
import { CustomToast } from "../../../stories/Toast";
import { UserService } from "../../../services/UserService";
import Loader from "../../Shimmer/Loader";

const QPFPCertificationDashboard = () => {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (_.isEmpty(userDetails)) {
      return;
    }
    if (userDetails.qpfp_certification_status === "inactive") {
      CustomToast.info(
        "You are not eligible for QPFP Certificate Registration Process."
      );
      navigate("/");
      return;
    }
    if (userDetails.qpfp_certification_status === "not_registered") {
      // CustomToast.info("You haven't registered for the QPFP Certification.");
      navigate(
        "/qpfp-certificant/registration/" +
          userDetails?.qpfp_certification_course_id
      );
      return;
    }
  }, [userDetails]);

  const fetchData = async () => {
    try {
      const response = await UserService.fetchQPFPCertificationStatus();
      setStatus(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <QPFPSkeleton showSidebar={false} showFooter={true}>
        <div className="h-20" />
        <Loader />
      </QPFPSkeleton>
    );
  }
  return (
    <QPFPSkeleton showSidebar={false} showFooter={true}>
      <NavMenu />

      <HeroSection certificationStatus={status} />
      <MainSection certificationStatus={status} />
    </QPFPSkeleton>
  );
};

export default QPFPCertificationDashboard;
