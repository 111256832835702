/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useNavigate } from "@reach/router";

import { User } from "../../store";
import ImageComponent from "../../stories/ImageComponent";
import Link from "../../stories/Link";
import AppSkeleton from "../AppSkeleton";
import LoadingModal from "../Modals/LoadingModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import { confettiAnimation } from "../../util/confettiAnimation";
import { useProfile } from "../../context/userProvider";
import EventLogoImg from "../../assets/images/event_logo.png";
import EventTicket from "../../assets/svgs/event_tickets.svg";
import EventCalendar from "../../assets/svgs/event_calendar.svg";
import EventClock from "../../assets/svgs/event_clock.svg";
import EventLocation from "../../assets/svgs/event_location.svg";
import EventBadge from "../../assets/images/event_badge.png";
import EventBadgeBGLeft from "../../assets/images/event_badge_bg_left.png";
import EventBadgeBGRight from "../../assets/images/event_badge_bg_right.png";

const NationalEventRegistrationThankyouScreen = (props) => {
  const navigate = useNavigate();
  const confettiCanvas = useRef();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const [propData, setPropData] = useState({});
  const isNewUser = !User.getToken();
  const { userDetails } = useProfile();

  useEffect(() => {
    if (_.isEmpty(props?.location?.state)) {
      navigate(-1);
      return;
    } else {
      setPropData(
        props?.location?.state ?? {
          eventDetails: {},
          formData: {},
        }
      );
    }
  }, []);

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      {!_.isEmpty(props?.location?.state) && (
        <LoadingModal open={_.isEmpty(props?.location?.state)} />
      )}
      <div className="flex flex-col items-center py-6 p x-4 lg:px-16 h-fit">
        <div className="relative flex flex-col items-center w-full max-w-5xl px-6 pb-10 rounded-xl bg-white lg:py-10">
          <div className="flex flex-col items-center w-full max-w-3xl bg-white">
            <ImageComponent
              src={EventLogoImg}
              className="mt-8 mb-2 h-20 !bg-white md:mt-0"
            />
            <div className="flex mt-2 tracking-tight text-center text-theme-orange text-2xl font-extrabold">
              {userDetails?.first_name !== undefined
                ? `Hey ${userDetails?.first_name}`
                : ""}{" "}
              {userDetails?.last_name}
            </div>
            <div className="flex justify-center w-full max-w-xl text-2xl font-extrabold leading-none tracking-tight text-center lg:text-4xl text-theme-black-color">
              Congratulations! You are all set
            </div>
            <div className="flex mt-2 tracking-tight text-center text-theme-black-300 max-w-[512px]">
              You are successfully registered for{" "}
              {propData?.eventDetails?.title ?? "event"}. Below are details and
              important next steps and links.
            </div>

            <div className="mt-10 p-5 rounded-xl bg-[#F5F7FB]">
              <div className="mb-6 grid grid-cols-1 justify-between items-start gap-y-6 md:grid-cols-2">
                <ShowDetails
                  label="Ticket"
                  value={
                    propData?.formData?.ticket_type?.label?.split("@")[0] ?? "-"
                  }
                />
                <ShowDetails
                  label="Dates"
                  value={_.head(propData?.eventDetails?.time) ?? "-"}
                />
                <ShowDetails
                  label="Timing"
                  value={_.nth(propData?.eventDetails?.time, 1) ?? "-"}
                />
                <ShowDetails
                  label="Location"
                  value={propData?.eventDetails?.venue?.name ?? "-"}
                />
              </div>

              <p className="text-md text-theme-black-300">
                <span className="font-medium text-theme-red italic">Note:</span>{" "}
                The recordings for Gold-Recording and Silver-Recording Ticket
                holders will be available after 1 month
                {/* on{" "}
                <a
                  href="https://learn.networkfp.com"
                  className="text-[#1B2ED6]"
                >
                  learn.networkfp.com
                </a> */}
              </p>
            </div>
            {isNewUser && (
              <p className="my-4 text-md text-theme-black-300">
                Please check your email id{" "}
                <span className="text-theme-red">
                  {propData?.formData?.email ?? ""}
                </span>
                . We have sent an email to reset your password. Complete your
                sign-up process to access your account.
              </p>
            )}

            {
              isNewUser ? (
                <Actions
                  navigate={navigate}
                  label="List of Delegates"
                  description={`Check out list of all registered delegates at ${
                    propData?.eventDetails?.title ?? "NFP National 2025"
                  }`}
                  link={`/events/${props.event_id}/delegates`}
                  solo={true}
                  btnLabel="View List"
                />
              ) : (
                // userDetails.is_pro_member ?
                <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                  <Actions
                    navigate={navigate}
                    label="List of Delegates"
                    description={`Check out list of all registered delegates at ${
                      propData?.eventDetails?.title ?? "NFP National 2025"
                    }`}
                    link={`/events/${props.event_id}/delegates`}
                    btnLabel="View List"
                  />
                  <Actions
                    navigate={navigate}
                    label="Partners Directory"
                    description="Search, Explore and Connect with NFP Partners & Supporters "
                    link={"https://partners.networkfp.com/"}
                    btnLabel="Visit Directory"
                  />
                  <Actions
                    navigate={navigate}
                    label="Go to Your Dashboard"
                    description="Login, explore & access your NFP programs and benefits here"
                    link="/"
                    btnLabel="Dashboard"
                  />
                </div>
              )
              //  : (
              //   <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              //     <Actions
              //       navigate={navigate}
              //       label="List of Delegates"
              //       description={`Check out list of all registered delegates at ${
              //         propData?.eventDetails?.title ?? "NFP National 2025"
              //       }`}
              //       link={`/events/${props.event_id}/delegates`}
              //       btnLabel="View List"
              //     />
              //     <Actions
              //       navigate={navigate}
              //       label="Upgrade to ProMembership"
              //       description="Become a NFP ProMember & get 9 annual benefits. Get ₹3,000 discount, use code NFP20253K"
              //       link="/members/pro_member/register"
              //       btnLabel="Upgrade"
              //     />
              //     <Actions
              //       navigate={navigate}
              //       label="Go to Your Dashboard"
              //       description="Login, explore & access your NFP programs and benefits here"
              //       link="/"
              //       btnLabel="Dashboard"
              //     />
              //   </div>
              // )
            }
          </div>
          <canvas
            ref={confettiCanvas}
            className="absolute z-50 w-full h-full"
          />
        </div>

        <div className="mt-8 flex flex-col items-center w-full max-w-5xl px-6 py-10 rounded-xl bg-white lg:py-10">
          <div className="flex flex-col items-center w-full max-w-3xl">
            <p className="font-extrabold text-4xl text-[#2A2A2F] text-center">
              Share with World!
            </p>

            <p className="mt-2 text-theme-black-300 text-center">
              Share the batch on social media with industry friends and clients
              / prospects.
            </p>

            <div className="mt-10 relative py-8 px-5 w-full flex flex-col items-center rounded-xl bg-[#F5F7FB]">
              <img
                src={EventBadgeBGLeft}
                alt="Event Badge BG Left"
                className="hidden h-full absolute left-0 top-0 md:block"
              />
              <img
                src={EventBadgeBGRight}
                alt="Event Badge BG Right"
                className="hidden h-full absolute right-0 top-0 md:block"
              />
              <img src={EventBadge} alt="Event Badge" className="w-64 h-64" />

              <a
                href={EventBadge ?? "#"}
                download="EventBadge"
                className="mt-6 max-w-[111px] w-full !h-10 !rounded-md flex items-center justify-center bg-theme-red text-white"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

const ShowDetails = ({ label, value }) => {
  const getIcon = () => {
    switch (label) {
      case "Ticket":
        return EventTicket;

      case "Dates":
        return EventCalendar;

      case "Timing":
        return EventClock;

      case "Location":
        return EventLocation;

      default:
        return EventTicket;
    }
  };

  return (
    <div className="flex space-x-4 items-center">
      <img src={getIcon()} alt={`Event ${label} Icon`} className="w-10 h-10" />
      <div className="flex flex-col">
        <p className="text-xs text-theme-black-300">{label}</p>
        <p className="font-medium text-sm text-theme-black-color">{value}</p>
      </div>
    </div>
  );
};

const Actions = ({
  navigate,
  label,
  description,
  btnLabel,
  link,
  solo = false,
}) => {
  return (
    <div className="mt-4 w-full flex flex-col justify-between p-5 rounded-xl bg-[#F5F7FB]">
      <div>
        <p
          className={`font-bold text-theme-black-color ${
            solo ? "text-center" : "text-left"
          }`}
        >
          {label}
        </p>
        <p
          className={`text-theme-black-300 ${
            solo ? "text-center" : "text-left"
          }`}
        >
          {description}
        </p>
      </div>
      <Link
        className={`${
          solo ? "w-1/2" : "w-full"
        } mt-4 mx-auto uppercase !rounded-md px-6 py-2 flex cursor-pointer items-center font-medium shadow-sm focus:outline-none focus:ring-0 justify-center text-base bg-white text-theme-red-300 border border-theme-red-300 hover:bg-theme-red-300 hover:text-white`}
        underLineOnlyOnHover={false}
        href={link}
        target="_blank"
      >
        {btnLabel}
      </Link>
    </div>
  );
};

NationalEventRegistrationThankyouScreen.propTypes = {
  eventDetails: PropTypes.shape({
    description: PropTypes.string,
    short_title: PropTypes.string,
    title: PropTypes.string,
    whatNextContent: PropTypes.array,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
};

export default NationalEventRegistrationThankyouScreen;
